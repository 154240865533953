<template>
  <div>
    <v-row v-if="item.id" dense>
      <!-- {{item}} -->
      <v-col v-if="hasInformation" cols="4">
        <p><strong>Conteúdo da Evolução</strong></p>
        <p v-if="item.profissional.apelido"> <strong>Profissional: </strong> {{ item.profissional.apelido }}</p>
        <p v-if="item.timestamp"> <strong>Data da Evolução:   </strong> {{ formatTimestampDiaSemana(item.timestamp) }} - {{formatTime(item.hora_inicio)}} às {{formatTime(item.hora_fim)}}</p>
        <p v-if="item.quadro_clinico && (item.especialidade.id === 3 || item.especialidade.id === 4) "> <strong>Histórico Clínico:   </strong> {{ item.quadro_clinico }}</p>
        <p v-if="item.diagnostico_clinico"> <strong>Diagnostico Clínico:   </strong> {{ item.diagnostico_clinico }}</p>
        <p v-if="item.exame_fisico"> <strong>Exame Físico:   </strong> {{ item.exame_fisico }}</p>
        <p v-if="item.conduta"> <strong>Conduta:   </strong> {{ item.conduta }}</p>
        <p v-if="item.texto_evolucao"><strong> Texto da Evolução:   </strong> {{ item.texto_evolucao }}</p>
        <p v-if="item.texto_meta"><strong>Texto da Meta:   </strong> {{ item.texto_meta }}</p>
        <p v-if="item.texto_info"> <strong>Texto da Informação para Prorrogação:   </strong> {{ item.texto_info }}</p>
        <p v-if="item.paciente && item.paciente.respirador"> 
          <strong>Uso do Respirador: </strong> De forma {{ item.uso_respirador }}
        </p>
        <p v-if="item.timestamp_sign"> <strong>Data da Coleta da Assinatura: </strong> {{ formatTimestamp(item.timestamp_sign) }}</p>
      </v-col>
      <v-col v-else>
        <p><strong>Sem Evolução lançada</strong></p>
      </v-col>
      <v-col cols="2"></v-col>
      <v-col cols="4">
        <p> <strong>Pagamento Prestador: </strong> {{ item.pagamento ? 'SIM': 'NÃO' }}</p>
        <p> <strong>Valor do Prestador: </strong> {{ formatCurrencyZero(item.valor_pagamento) }}</p>
        <p> <strong>Offline: </strong> {{ item.offline ? 'SIM': 'NÃO' }}</p>
        <!-- <p> <strong>Quantidade: </strong> {{ item.quantidade }}</p> -->
        <!-- <p> <strong>Código do Procedimento: </strong> {{ item.procedimento.codigo }}</p> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UtilsFunc from "../../../service/utilsFunc";

export default {
  name: "Evolucaoexpand",
  data: () => UtilsFunc.withCRUDUtils({
    isLoading: true,
  
  }), 
  props: {
    item: Object,
  },
  methods: {
    formatTimestamp(timestamp) {
        let date = new Date(timestamp);
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0'); 
        let year = date.getFullYear();
        return `${day}/${month}/${year}`;
    },
    formatCurrencyZero(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    },
    formatTimestampDiaSemana(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const weekDay = date.toLocaleDateString('pt-BR', { weekday: 'long' });

        return `${day}/${month}/${year} - ${weekDay.charAt(0).toUpperCase() + weekDay.slice(1)}`;
      }
      return '';
    },
    formatTime(value) {
      if (!value) return '';
      const parts = value.split(':');
      return parts[0] + ':' + parts[1];  // HH:MM
    },
  },
  computed: {
    // Verificar se tem informações no endpooint, caso sonctrário mostrará apenas uma meg de nao tem informações
    hasInformation() {
      return this.item && (
        this.item.texto_evolucao ||
        this.item.texto_meta ||
        this.item.texto_info ||
        this.item.timestamp_sign
      );
    }
  },
};
</script>

<style scoped>
  /*  */
</style>
